import { Helmet } from 'react-helmet'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Footer from '../footer'
import Modal from '../modal'

// import CountDownOpenEnrollment from '../CountDownOpenEnrollment'

import '../../scss/main.scss'

class Layout extends Component {
  constructor(props) {
    super(props)
    if (props.includeCitySearch) {
      props.checkUserCity()
    }
  }

  componentDidMount = () => {
    // set / get skipPrepopQuestions from redux
    this.props.checkPrePopulatedData(
      this.props.location.search,
      this.props.skipPrepopQuestions,
    )
    this.props.checkUrlSrc(this.props.location.search)
    this.props.checkUrlCampaign(this.props.location.search)
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.captive.campaign !== prevProps.captive.campaign) {
      if (window.dataLayer) {
        window.dataLayer.push({
          utm_campaign: this.props.captive.campaign,
        })
      }
    }
    if (this.props.captive.src !== prevProps.captive.src) {
      if (window.dataLayer) {
        window.dataLayer.push({
          src: this.props.captive.src,
        })
      }
    }
  }

  render() {
    const {
      children,
      phone,
      hideFooter,
      hideCta,
      footerAlt,
      hideOnline,
      showCountDown,
    } = this.props
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={(data) => (
          <div id="wrapper" className={this.props.newFont ? 'new-font' : ''}>
            {/*showCountDown && <CountDownOpenEnrollment phone={phone} /> */}
            <Helmet key="ipqualityscore">
              <script
                src="https://www.ipqualityscore.com/api/quotes.medicarecompanion.org/nlIWLg1XGUaQl5aFj1k13UJeh1KT3vGr7RJ2NCmLsZE0yVyjnqYiEQKnp1bu4IUIqk6fXCASrPUbdBdjAsJCZnhXSsb1LBNDU9RMZga5s9x457i9dvzVJn5sjrv9u1uvBvR8sapckUsp6TcGR25wdX69bboavYtbTNdi9ylNG44uJAPLyYVLMvcGS7zCzZ4gRUlFIsTC3E40Mf7VdN1jRg3qAhKeyyd9kU7fEr2uoi0vAsgOyZpXAJKQTz8GlOIZ/learn.js"
                crossorigin="anonymous"
              ></script>
              <noscript>
                {`<img
                  src="https://www.ipqualityscore.com/api/quotes.medicarecompanion.org/nlIWLg1XGUaQl5aFj1k13UJeh1KT3vGr7RJ2NCmLsZE0yVyjnqYiEQKnp1bu4IUIqk6fXCASrPUbdBdjAsJCZnhXSsb1LBNDU9RMZga5s9x457i9dvzVJn5sjrv9u1uvBvR8sapckUsp6TcGR25wdX69bboavYtbTNdi9ylNG44uJAPLyYVLMvcGS7zCzZ4gRUlFIsTC3E40Mf7VdN1jRg3qAhKeyyd9kU7fEr2uoi0vAsgOyZpXAJKQTz8GlOIZ/pixel.png"
                  alt=""
                />`}
              </noscript>
              <script>
                {`
if(typeof Startup !== "undefined") {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  // Startup.Store('transactionID', '');
  // Startup.Store('userID', '');
  // Startup.Store('subUserID', '');
  // Startup.Store('campaignID', '');
  // Startup.Store('subCampaignID', '');
  // Startup.Store('publisherID', '');
  // Startup.Store('gclid', '');
  // Startup.Store('clickID', '');
  // Startup.Store('conversionID', '');
  Startup.Store('Sub_ID', urlParams.get('Sub_ID'));
  Startup.Store('Src_ID', urlParams.get('Src_ID'));
  Startup.Store('SRC', urlParams.get('SRC'));
  Startup.Store('Pub_ID', urlParams.get('Pub_ID'));
  Startup.Store('phone', urlParams.get('phone'));

} 

if(typeof Startup !== "undefined") {
  Startup.AfterResult(function(result){
    // redirect or perform business logic if Fraud Score is >= recommended value of 90
    if(result.fraud_chance >= 90) {
      // window.location.href = "https://www.google.com"; 
    }
  });
  
  Startup.AfterFailure(function(reason){
    // user has blocked the second JavaScript call
    // can redirect or perform other business logic if JS is not loaded
    // window.location.href = "https://www.google.com";
  });
}

if(typeof Startup === "undefined") {
  // user has blocked the initial JavaScript call
  // can redirect or perform other business logic if JS is not loaded
  // window.location.href = "https://www.google.com";
}
`}
              </script>
            </Helmet>
            <a className="accessibility" href="#main">
              Skip to Content
            </a>
            {children}
            {!hideFooter && (
              <Footer
                phone={phone}
                hideCta={hideCta}
                footerAlt={footerAlt}
                hideOnline={hideOnline}
                TTYBig={this.props.TTYBig || this.props?.captive?.homeOrigin === 'home'}
                addLegalCopy={
                  this.props.addLegalCopy || this.props?.captive?.homeOrigin === 'home'
                }
              />
            )}
            <a className="accessibility" href="#wrapper">
              Back to top
            </a>
            <Modal type="terms" />
            <Modal type="privacy" />
          </div>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  phone: PropTypes.string,
  hideFooter: PropTypes.bool,
  hideCta: PropTypes.bool,
  footerAlt: PropTypes.bool,
  includeCitySearch: PropTypes.bool,
  hideOnline: PropTypes.bool,
  newFont: PropTypes.bool,
  TTYBig: PropTypes.bool,
  skipPrepopQuestions: PropTypes.bool,
  addLegalCopy: PropTypes.bool,
}

Layout.defaultProps = {
  phone: ``,
  hideFooter: false,
  hideCta: false,
  footerAlt: false,
  includeCitySearch: false,
  hideOnline: false,
  newFont: false,
  TTYBig: false,
  showCountDown: false,
  skipPrepopQuestions: false,
  addLegalCopy: false,
}

export default Layout
