import { connect } from 'react-redux'

import {
  checkPrePopulatedData,
  checkUrlCampaign,
  checkUrlSrc,
  checkUserCity,
} from '../../reducers/captive'

import Layout from './layout'

export default connect((state) => state, {
  checkPrePopulatedData,
  checkUrlCampaign,
  checkUrlSrc,
  checkUserCity,
})(Layout)
